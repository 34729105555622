export default function () {
    return {
        src: '',
        file: {
            name: ''
        },
        legal: {
            licenseText: '',
            id: '',
            url: '',
            author: '',
            license: {
                full: '',
                short: '',
                url: '',
            }
        }
    };
}