export const serverSidePresets = [
    {
        group: 'Bildschirm',
        name: 'Bildschirmfüllend 4:3',

        canvas: {
            width: 1024,
            height: 768,
        },
        file: {
            append: '_screen43',
            type: 'image/jpeg',
            suffix: 'jpg',
        }
    },
    {
        group: 'Bildschirm',
        name: 'Bildschirmfüllend 16:9',
        canvas: {
            width: 1280,
            height: 720,
        },
        file: {
            append: '_screen169',
            type: 'image/jpeg',
            suffix: 'jpg',
        }
    },
    {
        group: 'Soziale Medien',
        name: 'Instagram',
        canvas: {
            width: 1024,
            height: 1024,
        },
        file: {
            append: '_insta',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Soziale Medien',
        name: 'Story (Instagram/Facebook)',
        canvas: {
            width: 1080,
            height: 1920,
        },
        file: {
            append: '_story',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Soziale Medien',
        name: 'FB Seitentitel',
        canvas: {
            width: 820,
            height: 312,
        },
        file: {
            append: '_fbpage',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Soziale Medien',
        name: 'FB Veranstaltung',
        canvas: {
            width: 1200,
            height: 628,
        },
        file: {
            append: '_fbevent',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Soziale Medien',
        name: 'YouTube Kanaltitel',
        canvas: {
            width: 2560,
            height: 1440,
        },
        file: {
            append: '_ytchannel',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Tailfingen',
        name: 'Bild für die CommuniApp',
        canvas: {
            width: 550,
            height: 275,
        },
        file: {
            append: '_communi',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Tailfingen',
        name: 'Sliderbild Homepage',
        canvas: {
            width: 1170,
            height: 477,
        },
        file: {
            append: '_slider',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
    {
        group: 'Tailfingen',
        name: 'Newsletter, gesamte Breite',
        canvas: {
            width: 250,
            height: 150,
        },
        file: {
            append: '_newsletter',
            type: 'image/jpeg',
            suffix: 'jpg',
        },
        imprint: {
            color: '#ffffff',
            font: 'Sarabun',
            size: 100,
            alignmentH: 'right',
            alignmentV: 'bottom',
        }
    },
];
