<template>
  <div class="cropper-ui">
    <div class="row" @crop="doCrop">
      <div class="col-md-3 px-0 tools">
        <preview-toolset :config="config" :preview-image="previewImage" :open="toolsets.PreviewToolset"
                         :picking="state.picking" :legal="myTargetImage.legal"
                         :key="previewImage" @reset="$emit('reset')" @crop="doCrop"/>
        <transform-toolset :config="config" :open="toolsets.TransformToolset"
                           :rotation="currentRotation" :key="currentRotation"
                           @rotate="rotateBy" @rotate-to="rotateTo" @flip="flip"/>
        <parameters-toolset :config="config" :open="toolsets.ParametersToolset" :current-preset="currentPreset"
                            :presets="presets" @set-aspect-ratio="setAspectRatio" @set-preset="setPreset"/>
        <imprint-toolset :config="config" :open="toolsets.ImprintToolset" :picking="state.picking"
                         @set-picking="state.picking = $event" />
        <copyrights-toolset :config="config" :open="toolsets.CopyrightsToolset" :target-image="myTargetImage"
                            :picking="state.picking" />
      </div>
      <div class="col-md-9 px-0 crop-area">
        <cropper :src="myTargetImage.src" ref="cropper"
                 :stencil-props="config.stencil || {}"
                 :canvas="config.canvas || {}"
                 @change="updatePreview"/>
      </div>
    </div>
  </div>
</template>

<script>

import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import _ from 'lodash';
import {serverSidePresets} from "@/config/presets";
import CanvasText from "@/gfx/CanvasText";
import {defaults} from "@/config/defaults";
import exif from "@/gfx/exif";

// toolsets
import PreviewToolset from "@/components/Toolsets/PreviewToolset";
import TransformToolset from "@/components/Toolsets/TransformToolset";
import ParametersToolset from "@/components/Toolsets/ParametersToolset";
import CopyrightsToolset from "@/components/Toolsets/CopyrightsToolset";
import ImprintToolset from "@/components/Toolsets/ImprintToolset";


export default {
  name: "CropperUI",
  props: ['targetImage', 'source'],
  components: {
    Cropper,
    PreviewToolset,
    TransformToolset,
    ParametersToolset,
    CopyrightsToolset,
    ImprintToolset,
  },
  mounted() {
      this.$root.$on('set-picker', this.setPicker);
      this.setAspectRatio();
  },
  beforeDestroy(){
      this.$root.$off('set-picker', this.setPicker)
  },
  data() {
    let presetGroups = _.chain(serverSidePresets).groupBy('group').map((value, key) => ({
      group: key,
      presets: value
    })).value();
    let preset = defaults;
    _.merge(preset, presetGroups[0].presets[0]);

    let myTargetImage = this.targetImage;
    if (this.source) {
      myTargetImage.legal.licenseText = this.source;
    }

    return {
      myTargetImage,
      toolsets: {
        PreviewToolset: true,
        TransformToolset: true,
        ParametersToolset: true,
        CopyrightsToolset: true,
        ImprintToolset: true,
      },
      customRotation: 0,
      currentRotation: 0,
      previewImage: null,
      currentPreset: preset.name,
      config: preset,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      presets: presetGroups,
      presetPickerSettings: {
        options: presetGroups,
        labelField: 'name',
        valueField: 'name',
      },
      state: {
        picking: '',
      }
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.updatePreview({coordinates: this.coordinates, canvas: this.$refs.cropper.getResult().canvas});
      }
    },
    'targetImage.legal': {
      deep: true,
      handler() {
        this.updatePreview({coordinates: this.coordinates, canvas: this.$refs.cropper.getResult().canvas});
      }
    }
  },
  methods: {
    setPicker(e) {
      this.state.picking = e;
    },
    updatePreview({coordinates, canvas}) {
      this.coordinates = coordinates;

      let tmpCanvas = document.createElement('canvas');
      let tmpCtx = tmpCanvas.getContext("2d");
      tmpCanvas.width = canvas.width;
      tmpCanvas.height = canvas.height;
      tmpCtx.drawImage(canvas, 0, 0);

      tmpCtx = CanvasText.legalText(tmpCtx, this.targetImage.legal, this.config.legal);
      tmpCtx = CanvasText.imprint(tmpCtx, this.config.imprint);
      this.previewImage = tmpCanvas.toDataURL();

      this.$forceUpdate();
    },
    doCrop() {
      var canvas = this.$refs.cropper.getResult().canvas;
      var ctx = canvas.getContext('2d');

      ctx = CanvasText.legalText(ctx, this.targetImage.legal, this.config.legal);
      ctx = CanvasText.imprint(ctx, this.config.imprint);
      let result = exif.setExifInfo(ctx.canvas.toDataURL(this.config.file.type || 'image/jpeg'), this.targetImage, this.config);

      var link = document.createElement('a');
      link.download = (this.targetImage.file.name || this.config.file.name || defaults.file.name)
          + (this.config.file.append || defaults.file.append) + '.' + (this.config.file.suffix || defaults.file.suffix);
      link.href = result;
      link.click();
      link.remove();
    },
    setAspectRatio() {
      if (this.config.canvas.width && this.config.canvas.height) {
        this.config.stencil.aspectRatio = this.config.canvas.width / this.config.canvas.height;
      } else {
        this.config.stencil.aspectRatio = null;
      }
      this.$refs.cropper.$forceUpdate();
      this.$refs.cropper.refresh();
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    }
    ,
    rotateBy(angle) {
      if (isNaN(angle)) return;
      if (isNaN(this.currentRotation)) return;
      this.$refs.cropper.rotate(angle);
      this.currentRotation = parseInt((angle - this.currentRotation) % 360);
      this.$forceUpdate();
    },
    rotateTo(angle) {
      if (isNaN(angle)) return;
      if (isNaN(this.currentRotation)) return;
      let adjustedAngle = (angle - this.currentRotation) % 360;
      if (isNaN(adjustedAngle)) return;
      this.$refs.cropper.rotate(adjustedAngle);
      this.currentRotation = parseInt(angle);
      this.$forceUpdate();
    },
    setPreset(preset) {
      let rawPreset = null;
      this.presets.forEach(group => {
        group.presets.forEach(item => {
          if (item.name == preset) rawPreset = item;
        })
      });
      if (!rawPreset) return;
      _.merge(this.config, defaults, rawPreset);
      this.setAspectRatio();
    },
  },
}
</script>

<style scoped>
.cropper-ui {
  min-height: 100vh;
  background-color: black;
}

.crop-area {
  max-height: 100vh;
}


.color-preview {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid 1px white;
  border-radius: 2px;
}


</style>