<template>
  <div class="cutter3-layout">
      <slot />
  </div>
</template>

<script>

export default {
  name: "Cutter3Layout",
  props: ['targetImage'],
  data() {
    return {
      myTargetImage: this.targetImage,
    }
  },
}
</script>

<style scoped>

</style>