<template>
  <cutter3-layout :target-image="targetImage">
    <cropper-u-i v-if="targetImage.src" :target-image="targetImage" @reset="resetImage" :source="source"/>
    <b-container v-if="!targetImage.src">
      <pixabay-source v-model="targetImage"/>
      <hr />
      <upload-source v-model="targetImage" @input="source = 'MidJourney AI / Christoph Fischer'"/>
    </b-container>
  </cutter3-layout>
</template>

<script>
import Cutter3Layout from "@/layouts/Cutter3Layout";
import PixabaySource from "@/components/Sources/PixabaySource";
import UploadSource from "@/components/Sources/UploadSource.vue";
import CropperUI from "@/components/Cropper/CropperUI";
import Image from "@/structure/Image";

export default {
  name: 'App',
  components: {
    Cutter3Layout,
    CropperUI,
    PixabaySource,
    UploadSource,
  },
  data() {
    return {
      targetImage: Image(),
      source: '',
    }
  },
  methods: {
    resetImage() {
      this.targetImage = Image();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
