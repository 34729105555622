<template>
  <div class="pixabay-source">
    <h2 class="mt-2">Vom Computer hochladen</h2>
    <div class="upload-container">
      <div class="drop-target" :key="dragging" :class="{dragging: dragging}">
        <input class="form-control-file"
               type="file" @change="handleInput"
               @dragenter="dragEnter" @dragleave="dragLeave" />
        Hier klicken oder Datei hierher ziehen...
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PixabaySource",
  data() {
    return {
      target: '',
      pixabayQuery: '',
      pixabayResults: {},
      component: this,
      page: 0,
      pages: 0,
      totalHits: 0,
      dragging: false,
      name: '',
    }
  },
  methods: {
    handleInput(e) {
      let myFile = e.target.files[0];
      let reader = new FileReader();
      let component = this;
      reader.onload = function() {
        component.$emit('input', {
          src: reader.result,
          file: { name: myFile.name },
          legal: {
            licenseText: '',
            id: '',
            url: '',
            author: '',
            license: {
              full: '',
              short: '',
              url: '',
            }
          }
        });
      }
      reader.readAsDataURL(myFile);
    },
    dragEnter() {
      this.dragging = true;
    },
    dragLeave() {
      this.dragging = false;
    },
  }
}

</script>

<style scoped>
.upload-container {
  display: inline-block;
  position: relative;
  height: 6em;
  width: 100%;
}

.drop-target {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .35rem;
  font-weight: bold;
  color: darkgray;

  min-height: 6rem;
  vertical-align: center;
  text-align: center;
  border: solid 1px rgb(206, 212, 218);
  background-color: #e9ecef;
}

.drop-target.dragging {
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control-file {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}


</style>