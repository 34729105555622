<template>
  <toolset-box title="Titeltext">
    <div class="form-group">
      <label for="legal">Titeltext</label>
      <b-form-input name="legal" v-model="myConfig.imprint.text" />
    </div>
    <div class="form-group">
      <label for="font">Schriftart</label>
      <b-form-input name="font" v-model="myConfig.imprint.font"/>
    </div>
    <div class="form-group">
      <label for="size">Schriftgröße (px)</label>
      <b-form-input name="size" v-model="myConfig.imprint.size"/>
    </div>
    <div class="form-group">
      <label for="imprintColor">Textfarbe</label>
      <color-picker v-model="myConfig.imprint.color" :picking="myPicking" id="imprintColor"
                    title="Textfarbe für den Bildtitel" />
    </div>
    <div class="form-group mt-1">
      <label>Ausrichtung horizontal</label>
      <b-form-radio-group id="imprintAlignH" v-model="myConfig.imprint.alignmentH" :options="[]" name="imprintAlignH"
                          buttons size="sm" button-variant="outline-secondary">
        <b-form-radio value="left"><span class="mdi mdi-format-align-left"
                                         title="Text linksbündig anordnen"></span></b-form-radio>
        <b-form-radio value="center"><span class="mdi mdi-format-align-center"
                                           title="Text zentriert anordnen"></span></b-form-radio>
        <b-form-radio value="right"><span class="mdi mdi-format-align-right"
                                          title="Text rechtsbündig anordnen"></span></b-form-radio>
      </b-form-radio-group>
    </div>
    <div class="form-group mt-1">
      <label>Ausrichtung vertikal</label>
      <b-form-radio-group id="imprintAlignV" v-model="myConfig.imprint.alignmentV" :options="[]" name="imprintAlignV"
                          buttons size="sm" button-variant="outline-secondary">
        <b-form-radio value="top"><span class="mdi mdi-format-align-top"
                                        title="Text an der Oberkante anordnen"></span></b-form-radio>
        <b-form-radio value="middle"><span class="mdi mdi-format-align-middle"
                                           title="Text in halber Höhe anordnen"></span></b-form-radio>
        <b-form-radio value="bottom"><span class="mdi mdi-format-align-bottom"
                                           title="Text an der Unterkante anordnen"></span></b-form-radio>
      </b-form-radio-group>
    </div>
  </toolset-box>
</template>

<script>
import ToolsetBox from './ToolsetBox';
import ColorPicker from "@/components/Cropper/ColorPicker";

export default {
  name: "ImprintToolset",
  components: {
    ToolsetBox,
    ColorPicker,
  },
  props: ['open', 'config', 'previewImage', 'preset', 'currentPreset', 'targetImage', 'picking'],
  data() {
    return {
      myPreset: this.currentPreset,
      myConfig: this.config,
      myTargetImage: this.targetImage,
      myPicking: this.picking,
    }
  }
}
</script>

<style scoped>

</style>