import piexif from "piexifjs";

export default {
    setExifInfo(data, targetImage, config) {
        let packageInfo = require('@/../package.json');

        var zeroth = {};
        var exif = {};
        var gps = {};
        zeroth[piexif.ImageIFD.ImageDescription] = config.imprint.text;
        zeroth[piexif.ImageIFD.Artist] = targetImage.legal.author;
        zeroth[piexif.ImageIFD.Copyright] = targetImage.legal.licenseText+', '+targetImage.legal.license.full;
        zeroth[piexif.ImageIFD.Software] = packageInfo.name+' '+packageInfo.version;
        zeroth[piexif.ImageIFD.ImageID] = targetImage.legal.id;
        var exifObj = {"0th":zeroth, "Exif":exif, "GPS":gps};

        var exifbytes = piexif.dump(exifObj);

        return piexif.insert(exifbytes, data)

    }
}