<template>
  <toolset-box title="Vorschau">
    <img :src="previewImage" class="mb-3 img-fluid preview" :class="{picking: picking}"
         ref="previewImg" @click="updateColorSelection"
    />
    <b-button class="me-1" variant="success"
              @click.prevent.stop="$emit('crop')"><span class="mdi mdi-crop"></span> Zuschneiden
    </b-button>
    <b-button class="me-1" variant="danger"
              @click.prevent.stop="$emit('reset')"><span class="mdi mdi-delete"></span> Neues Bild
    </b-button>
    <b-button v-if="legal.url" class="me-1" variant="light"
              @click.prevent.stop="openSource" title="Ursprüngliches Bild öffnent"><span class="mdi mdi-link"></span></b-button>
  </toolset-box>
</template>

<script>
import ToolsetBox from './ToolsetBox';

export default {
  name: "PreviewToolset",
  components: {
    ToolsetBox
  },
  props: ['open', 'config', 'previewImage', 'picking', 'legal'],
  methods: {
    updateColorSelection(e) {
      if (!this.picking) return;
      var color = '';

      var myImgElement = this.$refs.previewImg;
      var myCanvasElement = document.createElement("canvas");
      // don't forget to add it to the DOM!!
      myCanvasElement.width = myImgElement.width;
      myCanvasElement.height = myImgElement.height;
      var context = myCanvasElement.getContext('2d');
      context.drawImage(myImgElement, 0, 0, 1600, 900, 0, 0, myImgElement.width, myImgElement.height);

      var p = context.getImageData(e.offsetX, e.offsetY, 1, 1).data;
      if ((p[0] == 0) && (p[1] == 0) && (p[2] == 0) && (p[3] == 0)) {
        color = 'transparent';
      }

      color = "#" + ("000000" + this.rgbToHex(p[0], p[1], p[2])).slice(-6);
      myCanvasElement.remove();

      this.$root.$emit('pick', this.picking, color);
    },
    rgbToHex(r, g, b) {
      if (r > 255 || g > 255 || b > 255)
        throw "Invalid color component";
      return ((r << 16) | (g << 8) | b).toString(16);
    },
    openSource() {
      if (!this.legal.url) return;
      window.open(this.legal.url, '_blank').focus();
    }
  }
}
</script>

<style scoped>

</style>