import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=5ecf4a63&scoped=true&"
import script from "./ColorPicker.vue?vue&type=script&lang=js&"
export * from "./ColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=5ecf4a63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecf4a63",
  null
  
)

export default component.exports