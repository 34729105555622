import { render, staticRenderFns } from "./PixabaySource.vue?vue&type=template&id=be44e3c4&scoped=true&"
import script from "./PixabaySource.vue?vue&type=script&lang=js&"
export * from "./PixabaySource.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be44e3c4",
  null
  
)

export default component.exports