<template>
  <div class="color-picker mt-1">
    <b-button variant="secondary" size="sm" @click="displayPicker = !displayPicker" class="me-1 picker-button">
      <span class="current-color me-1" :style="'background-color: ' + myValue" ></span> {{ myValue}}
    </b-button>
    <b-button :variant="this.myPicking ? 'primary' : 'secondary'" size="sm"  @click="togglePreviewPicker" class=" picker-button">
      <span class="mdi mdi-eyedropper"></span>
    </b-button>
		<chrome-picker :value="myValue" @input="updateFromPicker" v-if="displayPicker"/>
  </div>
</template>

<script>
import {Chrome} from 'vue-color';

export default {
  name: "ColorPicker",
  props: ['value', 'title', 'picking', 'id'],
  components: {
    'chrome-picker': Chrome,
  },
  data() {
    return {
      myPicking: this.picking,
      myValue: this.value,
      displayPicker: false,
    }
  },
  mounted() {
    this.$root.$on('pick', this.updatePicker)
  },
  beforeDestroy() {
    this.$root.$off('pick', this.updatePicker)
  },
  watch: {
    myValue(val) {
      if(val) {
        this.updateColors(val);
        this.$emit('input', val);
        //document.body.style.background = val;
      }
    }
  },
  methods: {
    updateColors(color) {
      this.myValue = color;
      this.$forceUpdate();
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
          target = e.target;
      if(el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.myValue = color.hex;
      } else {
        this.myValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
      this.hidePicker();
    },
    updatePicker(picker, color) {
      if (picker != this.id) return;
      this.myValue = color;
      this.myPicking = '';
    },
    togglePreviewPicker() {
      if (this.myPicking == this.id) {
        this.myPicking = '';
      } else {
        if (this.myPicking == '') this.myPicking = this.id;
      }
      this.$root.$emit('set-picker', this.myPicking);
    }
  }
}
</script>

<style scoped>
.color-picker {
  display: inline-block;
  width: 50%;
}

.color-picker-badge {
  width: 10px;
  height: 10px;
  display: inline-block;
  border: solid 1px white;
  border-radius: 2px;
}

.eyedropper-state.picking {
  background-color: #333333;
}

.current-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #000;
  cursor: pointer;
}

.picker-button {
  font-size: .9em !important;
}
</style>