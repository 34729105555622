export default {
    legalText(context, legal, legalConfig) {
        if (legal.licenseText) {
            context.save()
            context.font = legalConfig.size+'px '+legalConfig.font;
            context.fillStyle = legalConfig.color || 'white';
            context.rotate(-90 * (Math.PI / 180));
            context.fillText('Bild: ' + legal.licenseText, -(context.canvas.height - 4), 10);
            context.restore();
        }
        return context;
    },
    imprint(context, imprintConfig) {
        context.save()
        var words = imprintConfig.text.split(' ');
        var line = '';

        var x;
        var y;
        var maxWidth = context.canvas.width - 20;
        var lineHeight = 0;

        context.font = imprintConfig.size+'px '+imprintConfig.font;
        context.fillStyle = imprintConfig.color || 'white';

        // separate lines
        var lines = [];
        for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            metrics.height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            var testWidth = metrics.width;
            if (lineHeight < metrics.height) lineHeight = metrics.height;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                lines.push(line);
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }
        if (line.trim() != '') lines.push(line);
        if (lines.length > 1) lineHeight = Math.floor(1.1*lineHeight); else lineHeight = Math.floor(lineHeight);

        switch(imprintConfig.alignmentV) {
            case 'top':
                y = lineHeight+10;
                break;
            case 'middle':
                y = Math.floor((context.canvas.height-20)/2)+10;
                break;
            case 'bottom':
                y = (context.canvas.height)-((lines.length-1)*lineHeight)-20;
                break;
        }
        switch(imprintConfig.alignmentH) {
            case 'left':
                x = 10;
                break;
            case 'center':
                x= Math.floor((context.canvas.width-20)/2)+10;
                break;
            case 'right':
                x = context.canvas.width-10;
                break;
        }
        context.textAlign = imprintConfig.alignmentH;

        // write text
        lines.forEach(line => {
            context.fillText(line.trim() || ' ', x, y);
            y += lineHeight;
        });

        context.restore();
        return context;
    }
}