<template>
  <toolset-box title="Transformieren">
    <b-button-toolbar key-nav aria-label="Transformationen">
      <b-button class="me-1" title="Nach links drehen" @click="$emit('rotate', -90)"><span
          class="mdi mdi-rotate-left"></span></b-button>
      <b-button class="me-1" title="Nach rechts drehen" @click="$emit('rotate', 90)"><span
          class="mdi mdi-rotate-right"></span></b-button>
      <b-button class="me-1" title="Horizontal spiegeln" @click="$emit('flip', true, false)"><span
          class="mdi mdi-flip-horizontal"></span></b-button>
      <b-button class="me-1" title="Vertikal spiegeln" @click="$emit('flip', false, true)"><span
          class="mdi mdi-flip-vertical"></span></b-button>
    </b-button-toolbar>
    <div class="form-group">
      <label>Rotation</label>
      <input class="form-control" type="number" v-model="myRotation" @input="$emit('rotate-to', $event.target.value)" :key="myRotation"/>
    </div>
  </toolset-box>
</template>

<script>
import ToolsetBox from './ToolsetBox';


export default {
  name: "TransformToolset",
  components: {
    ToolsetBox,
  },
  props: ['open', 'config', 'previewImage', 'rotation'],
  data() {
    return {
      myRotation: this.rotation,
    }
  }
}
</script>

<style scoped>

</style>