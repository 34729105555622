import { render, staticRenderFns } from "./ToolsetBox.vue?vue&type=template&id=af4d344c&scoped=true&"
import script from "./ToolsetBox.vue?vue&type=script&lang=js&"
export * from "./ToolsetBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af4d344c",
  null
  
)

export default component.exports