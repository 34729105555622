<template>
  <toolset-box title="Copyrights">
    <div class="form-group">
      <label for="legal">Quelle</label>
      <b-form-input name="legal" v-model="myTargetImage.legal.licenseText"/>
    </div>
    <div class="form-group">
      <label for="font">Schriftart</label>
      <b-form-input name="font" v-model="myConfig.legal.font"/>
    </div>
    <div class="form-group">
      <label for="size">Schriftgröße (px)</label>
      <b-form-input name="size" v-model="myConfig.legal.size"/>
    </div>
    <div class="form-group">
      <label for="legalColor">Textfarbe</label>
      <color-picker v-model="myConfig.legal.color" :picking="myPicking" id="legalColor"
                    title="Textfarbe für die Quellenangaben"/>
    </div>
    <div class="form-group">
      <label for="author">Urheber</label>
      <b-form-input name="size" v-model="myTargetImage.legal.author"/>
    </div>
    <div class="form-group">
      <label for="imageid">Bild ID</label>
      <b-form-input name="imageid" v-model="myTargetImage.legal.id"/>
    </div>
    <div class="form-group">
      <label for="license-full">Lizenzangaben</label>
      <b-form-input name="license-full" v-model="myTargetImage.legal.license.full"/>
    </div>
    <div class="form-group">
      <label for="license-short">Lizenz (Titel)</label>
      <b-form-input name="license-short" v-model="myTargetImage.legal.license.short"/>
    </div>
    <div class="form-group">
      <label for="license-url">Lizenz (URL)</label>
      <b-form-input name="license-url" v-model="myTargetImage.legal.license.url"/>
    </div>
  </toolset-box>
</template>

<script>
import ToolsetBox from './ToolsetBox';
import ColorPicker from "@/components/Cropper/ColorPicker";

export default {
  name: "CopyrightsToolset",
  components: {
    ToolsetBox,
    ColorPicker
  },
  props: ['open', 'config', 'targetImage', 'picking'],
  data() {
    return {
      myPreset: this.currentPreset,
      myConfig: this.config,
      myTargetImage: this.targetImage,
      myPicking: this.picking,
    }
  }
}
</script>

<style scoped>

</style>