<template>
  <div class="pixabay-source">
    <h2 class="mt-2">Auf Pixabay suchen</h2>
    <div class="mt-2 form-group" v-if="!searching">
      <label for="q">Suche nach:</label>
      <b-form-input name="q" v-model="pixabayQuery" autofocus @keydown.enter="getPage(component, page)"/>
    </div>
    <div v-if="searching" class="my-2">
      <span class="mdi mdi-spin mdi-loading"></span> Suche nach "{{ pixabayQuery}}" ...
    </div>
    <div v-if="(pixabayResults.length > 0) && (!searching)" :key="pixabayQuery+page" class="mt-2">
      <div class="mb-1">
        <button v-if="page > 1" class="btn btn-sm btn-light" @click.prevent.stop="getPage(component, page-1)">
          <span class="mdi mdi-chevron-left"></span>
        </button>
        Seite {{ page }} / {{ pages }} ({{ totalHits }} Ergebnisse) für "{{ pixabayQuery }}"
        <button v-if="page < pages" class="btn btn-sm btn-light" @click.prevent.stop="getPage(component, page+1)">
          <span class="mdi mdi-chevron-right"></span>
        </button>
      </div>
      <div :key="pixabayQuery+page">
        <img class="pixabay-preview img-thumbnail" v-for="(result,resultKey) in pixabayResults"
             :src="result.previewURL" title="Klicken, um dieses Bild auszuwählen" :key="resultKey"
             @click="choose(result)"/>
        />
      </div>
    </div>
  </div>
</template>

<script>
import __ from "lodash";
export default {
  name: "PixabaySource",
  data() {
    return {
      target: '',
      pixabayQuery: '',
      pixabayResults: {},
      component: this,
      page: 0,
      pages: 0,
      totalHits: 0,
      searching: false,
    }
  },
  methods: {
    choose(result) {
        this.$emit('input', {
          src: result.fullHDURL,
          file: { name: 'pixabay_'+result.user+'_'+result.id },
          legal: {
            licenseText: 'pixabay / '+result.user,
            id: result.id,
            url: result.pageURL,
            author: result.user,
            license: {
              full: 'Pixabay License, https://pixabay.com/de/service/license/',
              short: 'Pixabay License',
              url: 'https://pixabay.com/de/service/license/',
            }
          }
        });
    },
    getPage: __.debounce((component, page) => {
      if (!component.pixabayQuery) {
        component.result = {};
        component.totalHits = 0;
        component.pages = 0;
        return;
      }
      component.page = page;
      component.searching = true;
      window.axios.get('https://pixabay.com/api/?key='+process.env.VUE_APP_PIXABAY_API_KEY+'&q='+encodeURIComponent(component.pixabayQuery)+'&image_type=photo&page='+(page || 1))
          .then(response => {
            component.totalHits = response.data.totalHits;
            component.pixabayResults = response.data.hits;
            component.pages = Math.ceil(component.totalHits / 20);
            component.page = page || 1;
            component.searching = false;
          });
    }, 1000),
  }
}

</script>

<style scoped>

</style>