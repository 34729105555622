<template>
  <toolset-box title="Voreinstellungen">
    <div class="form-group">
      <label for="preset">Voreinstellung</label>
      <select v-model="myPreset" name="preset"
              @input="$emit('set-preset', $event.target.value)"
              class="form-control">
        <optgroup v-for="(group,groupKey) in presets" :key="groupKey" :label="group.group">
          <option v-for="(option,optionKey) in group.presets" :key="optionKey" :value="option.name">
            {{ option.name }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="width">Breite</label>
      <b-form-input name="width" v-model="myConfig.canvas.width" @change="$emit('set-aspect-ratio')"/>
    </div>
    <div class="form-group">
      <label for="height">Höhe</label>
      <b-form-input name="width" v-model="myConfig.canvas.height" @change="$emit('set-aspect-ratio')"/>
    </div>
    <div class="form-group">
      <label for="ratio">Seitenverhältnis</label>
      <b-form-input name="ratio" v-model="myConfig.stencil.aspectRatio" disabled/>
    </div>
  </toolset-box>
</template>

<script>
import ToolsetBox from './ToolsetBox';

export default {
  name: "ParametersToolset",
  components: {
    ToolsetBox
  },
  props: ['open', 'config', 'previewImage', 'presets', 'currentPreset'],
  data() {
    return {
      myPreset: this.currentPreset,
      myConfig: this.config,
    }
  }
}
</script>

<style scoped>

</style>