<template>
  <div class="toolset">
    <h2 @click="toggle">
      {{ title }}<span class="pull-right mdi"
                    :class="isOpen ? 'mdi-chevron-double-down' : 'mdi-chevron-double-right'"></span></h2>
    <div v-if="isOpen" class="p-3">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsetBox",
  props: ['open', 'title'],
  data() {
    return {
      isOpen: open,
    }
  },
  methods: {
    toggle() {
      this.isOpen != this.isOpen;
    },
  },
}
</script>

<style scoped>

</style>